<template>
  <div class="video-box" :style="{height:height+'px',width:width+'px'}">
    <div class="video" @mouseleave="mouseleaveVideo" @mousemove="mousemoveVideo" ref="video">
      <video
        ref="myvideo"
        @waiting="waiting"
        @loadstart="loadstart"
        :poster="poster"
        controlslist="nodownload"
        @canplay="getTotalTime"
        @timeupdate="timeUpdate"
        @ended="ended"
        @error="error"
        @loadedmetadata="loadedmetadata"
        @click="togglePlay"
        controls>
        <source  :src="srcUrl"/>您的浏览器不支持视频播放。
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: "ui-video",
  props: {
    srcUrl: {
      //视频路径
      type: String,
      default:"http://9890.vod.myqcloud.com/9890_4e292f9a3dd011e6b4078980237cc3d3.f30.mp4"
        // require("../../assets/1620807678424438.mp4"),
    },
    poster: {
      //还没加载完成之前显示的图片
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 574,
    },
    width: {
      type: Number,
      default: 1200,
    },
    isCustomTime: {
      //是否自定义时长
      type: Boolean,
      default: false,
    },
    myDuration: {
      //自定义时长
      type: Number,
      default: 0,
    },
    myBufferProgress: {
      //自定义缓冲区长度 当录制视频时缓冲区域默认为百分之百
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      playCur:0,
      // 用于存放<video>标签对象，方便调用原生API
      myvideo: null,
      video: null,
      // 是否暂停，用于控制播放与暂停
      isPaused: true,
      // 播放当前时间
      currentTime: "00:00",
      totalTime: "00:00", //总的播放时间
      duration: 0, //视频总共播放时间 单位秒
      playtime: 0, //当前视频播放了多少时间 单位秒
      // 声音是否静音
      isMuted: false,
      // 是否全屏
      isFullScreen: false,
      isMouseover: false, //鼠标是否移除进度条
      dragProgress: 0, //进度按钮位置
      bufferProgress: 0, //缓冲条
      showControls: false, //是否显示控制条
      timeout: 0, //定时器   控制着底部控制条的显示
      loading: true,
    };
  },
  mounted() {
    // 加载完毕后，先获取<video>标签DOM对象
    this.myvideo = this.$refs.myvideo;
    this.video = this.$refs.video;
    this.mousemoveVideo();
    
  },
  created(){
      
  },
  computed: {
    //计算当前的播放进度
    progress: {
      get() {
        let duration = this.duration;
        let playtime = this.playtime;
        let progress = (playtime / duration).toFixed(2) * 100;
        this.dragProgress = progress - 0.5;
        if (this.isCustomTime) {
          //如果为录制的视频 则缓冲区域为默认设置
          this.bufferProgress = this.myBufferProgress;
        } else {
          //如果不是录制的视频 则缓冲区域为当前的播放进度+5(不是真正的缓冲)
          if (progress > this.bufferProgress) {
            if (progress >= 95) {
              this.bufferProgress = 100;
            } else {
              this.bufferProgress = progress + 5;
            }
          }
        }
        return progress;
      },
    },
  },
  methods: {
    //重新加载
    load() {
      this.$refs.myvideo.load();
    },
    playVideo(){
      this.playCur = 1;
      this.$refs.myvideo.play();
    },
    //播放
    play() {
      this.$refs.myvideo.play();
    },
    //点击进度条将视频播放时间设置为对应的进度
    clickProgress(e) {
      let x = e.layerX;
      let clientWidth = this.$refs.progress.clientWidth;
      let y = (x / clientWidth).toFixed(2) * 100;
      if (y == 100) y == 99;
      this.myvideo.currentTime = this.duration * (y / 100);
      this.mousemoveVideo();
    },
    //鼠标移入视频播放容器时显示控制条
    mousemoveVideo() {
      clearTimeout(this.timeout);
      this.showControls = true;
      this.timeout = setTimeout(() => {
        this.showControls = false;
      }, 4000);
    },
    //鼠标移出时
    mouseleaveVideo() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.showControls = false;
      }, 4000);
    },
    mousemove() {
      this.isMouseover = true;
    },
    mouseleave() {
      this.isMouseover = false;
    },
    // 播放与暂停,注意,这里isPause的变化,对应图标的变化
    togglePlay() {
      this.isPaused = !this.isPaused;
      if (this.isPaused) {
        this.myvideo.pause();
      } else {
        this.myvideo.play();
      }
    },
    // 停止播放
    stopPlay() {
      this.myvideo.pause();
      this.myvideo.currentTime = 0;
      // 停止播放了，播放图标从暂停切换到播放,isPaused为true的时候，图标是播放
      this.isPaused = true;
      this.playCur = 0;
    },
    // 定义一个时间处理函数，例如将100秒，处理成1:40
    timeFormat(time) {
      let minute = Math.floor((time % 3600) / 60); // 取余
      let second = Math.floor(time % 60);
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return `${minute}:${second}`;
    },
    // 当事件oncanplay触发时，获取视频的总时间信息，然后通过timeFormat函数处理成00:00的格式，并渲染到template中
    getTotalTime() {
      this.currentTime = this.timeFormat(this.myvideo.currentTime);
      if (this.isCustomTime) {
        //是否使用自定义时长，兼容录制视频时MediaRecorder没有时长的问题
        this.totalTime = this.timeFormat(this.myDuration);
        this.duration = this.myDuration;
      } else {
        this.totalTime = this.timeFormat(this.myvideo.duration);
        this.duration = this.myvideo.duration;
      }
      setTimeout(() => {
        this.loading = false;
        this.myvideo.autoplay = true;
        this.myvideo.play();
        this.isPaused = !this.myvideo.autoplay;
      }, 500);
    },
    // 视频开始加载时触发
    loadstart() {
      this.loading = true;
    },
    //加载完成后触发
    loadedmetadata() {
        
    },
    //缓冲时触发
    waiting() {
      this.loading = true;
    },
    //视频播放结束时触发
    ended() {
      this.$emit("ended");
      this.playCur = 0;
    },
    //视频播放出现错误时触发
    error() {
      this.$emit("error");
    },
    // 时间timeupdate触发时，更新当前播放时间
    timeUpdate() {
      this.currentTime = this.timeFormat(this.myvideo.currentTime);
      this.playtime = this.myvideo.currentTime;
    },
    // 声音是否静音
    toggleMuted() {
      this.isMuted = !this.isMuted;
      this.myvideo.muted = !this.myvideo.muted;
    },
    // 切换全屏,注意，这里进入全屏时，使用的元素是this.video，而不是this.myvideo。video是myvideo的父标签，这样控制条才能正确显示
    toggleFullScreen(event) {
      let fullscreen = document.webkitIsFullScreen || document.fullscreen;
      this.isFullScreen = fullscreen;
      if (!this.isFullScreen) {
        this.isFullScreen = !this.isFullScreen;
        const inFun =
          this.video.requestFullscreen || this.video.webkitRequestFullScreen;
        inFun.call(this.video);
      } else {
        this.isFullScreen = !this.isFullScreen;
        const exitFun =
          document.exitFullscreen || this.document.webkitExitFullScreen;
        exitFun.call(document);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.video-box {
  position: relative;
  overflow: hidden;
}
.video-play-bg{
  height: 150px;
  width: 150px;
  position: absolute;
  top: calc((100% - 150px) / 2);
  left: calc((100% - 150px) / 2);
  cursor: pointer;
}
.video-play{
  height: 61px;
  width: 64px;
  position: absolute;
  top: calc((100% - 64px) / 2);
  left: calc((100% - 61px) / 2);
}
.video-loading {
  height: 100%;
  width: 100%;
  background: linear-gradient(178.41deg, #1b54dc -43.39%, #8aadff 96.64%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1000;
  .loading-donghua {
    width: 220px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    > div {
      animation: donghua1 0.3s infinite;
      -webkit-animation: donghua1 0.3s infinite;
      animation-fill-mode: forwards;
    }
    > :nth-child(1) {
      height: 50px;
      width: 10px;
      background-color: #fff;
      border-radius: 20px;
      position: absolute;
      left: 0px;
    }
    > :nth-child(2) {
      height: 100px;
      width: 10px;
      background-color: #fff;
      border-radius: 20px;
      position: absolute;
      left: 55px;
      animation: donghua2 0.3s infinite;
      -webkit-animation: donghua2 0.3s infinite;
      animation-fill-mode: forwards;
    }
    > :nth-child(3) {
      height: 200px;
      width: 10px;
      background-color: #fff;
      border-radius: 20px;
      position: absolute;
      left: 107px;
    }
    > :nth-child(4) {
      height: 100px;
      width: 10px;
      background-color: #fff;
      border-radius: 20px;
      position: absolute;
      right: 50px;
      animation: donghua2 0.3s infinite;
      -webkit-animation: donghua2 0.3s infinite;
      animation-fill-mode: forwards;
    }
    > :nth-child(5) {
      height: 50px;
      width: 10px;
      background-color: #fff;
      border-radius: 20px;
      position: absolute;
      right: 0px;
    }
    @keyframes donghua1 {
      0% {
        margin-top: 5px;
      }
      25% {
        margin-top: 10px;
      }
      50% {
        margin-top: 0px;
      }
      75% {
        margin-top: -10px;
      }
      100% {
        margin-top: -5px;
      }
    }
    @keyframes donghua2 {
      0% {
        margin-top: -10px;
      }
      25% {
        margin-top: -5px;
      }
      50% {
        margin-top: 0px;
      }
      75% {
        margin-top: 5px;
      }
      100% {
        margin-top: 10px;
      }
    }
  }
}
.video {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.video video {
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: 9999;
}

.controls {
  width: 100%;
  height: 76px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  background-blend-mode: multiply;
}

.con_top {
  height: 50%;
  position: relative;

  .con_content {
    height: 3px;
    width: 100%;
    background: rgba(234, 234, 234, 0.2);
    position: absolute;
    top: 34px;
    z-index: 2;
  }

  .con_cache {
    height: 3px;
    position: absolute;
    top: 34px;
    background: rgba(234, 234, 234, 0.5);
  }

  .con_progress {
    height: 3px;
    position: absolute;
    top: 34px;
    background: #fc0d1c;
  }

  .con_common {
    height: 5px;
  }

  .con_drag {
    position: absolute;
    top: 29px;
    width: 15px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    > div {
      height: 11px;
      width: 11px;
      background-color: #ffffff;
      border-radius: 50%;
    }
  }
}

.con_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50%;
  padding-right: 10px;
  padding-left: 10px;

  .con_time {
    font-size: 13px;
  }
}

.controls span {
  padding: 0 5px;
  color: #fff;
}

.cursor {
  cursor: pointer;
}
</style>
