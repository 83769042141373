<template>
  <div>
      <div class="content" :style="{width:width}" :class="cur==index?'active':''" @click="cilckMethod">
          <div class="title">{{item.title}}</div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        cur:{
            type:Number,
            default:0
        },
        index:{
            type:Number,
            default:0
        },
        width:{
            type:String,
            default:'400px'
        }
    },
    methods:{
      cilckMethod(){
        this.$emit('cilckMethod',{cur:this.cur});
      }
    },
}
</script>

<style scoped lang="scss">
.content{
    height: 85px;
}
.title{
    text-align: center;
    height: 85px;
    cursor: pointer;
    line-height: 85px;
    font-size: 18px;
    color: #333333;
}
.active{
    border: 1px solid #DDDDDD;
    background: #FCFCFC;
}
</style>