<template>
  <div class="main-content">
    <div class="headImg">
      <img src="../../assets/numberCloud/numberCloud-top.png" alt="">
      <navigationBar class="avigation"></navigationBar>
    </div>
    <div class="content-view">
      <div class="content">
        <div class="top-title">
          <topItem v-for="(item,index) in item" :key="index" :index="cur" :cur="index" :width="'300px'" :item="item" @cilckMethod="cilckMethod"></topItem>
        </div>
        <div class="title-view">
          <!-- 产品介绍 -->
          <div class="one" v-if="item1 != null">
            <div class="one-img">
              <img class="img1" v-if="item1 != null" :src="item1.img" alt="">
              <img class="left" src="../../assets/numberCloud/numberCloud-left.png" alt="">
            </div>
            <div class="one-nr">
              <div class="one-name" v-if="item1 != null">{{item1.title}}</div>
              <div class="one-title" v-if="item1 != null" v-html="item1.content"></div>
              <div class="play" :style="{backgroundColor:isSkin}" @click="playMethod()">
                <div class="name">产品样例</div>
                <img src="../../assets/numberCloud/numberCloud-play.png" alt="">
              </div>
            </div>
          </div>
          <!-- 结构图 -->
          <div class="two" v-if="item2 != null">
            <div class="two-nr">
              <div class="two-name" v-if="item2 != null">{{item2.title}}</div>
              <div class="two-title" v-if="item2 != null" v-html="item2.content"></div>
            </div>
            <div class="two-img">
              <img class="img2" v-if="item2 != null" :src="item2.img" alt="">
              <img class="left" src="../../assets/numberCloud/numberCloud-right.png" alt="">
            </div>
          </div>
          <!-- 产品亮点 -->
          <div class="three" v-if="item3 != null">
            <div class="three-img">
              <img class="img3" v-if="item3 != null" :src="item3.img" alt="">
              <img class="left" src="../../assets/numberCloud/numberCloud-left.png" alt="">
            </div>
            <div class="three-nr">
              <div class="three-name" v-if="item3 != null">{{item3.title}}</div>
              <div class="three-title" v-if="item3 != null" v-html="item3.content"></div>
            </div>
          </div>
          <!-- 应用示范 -->
          <div class="four" v-if="item4 != null">
            <div class="four-nr">
              <div class="four-name" v-if="item4 != null">{{item4.title}}</div>
              <div class="four-title" v-if="item4 != null" v-html="item4.content"></div>
            </div>
            <div class="four-img">
              <img class="img4" v-if="item4 != null" :src="item4.img" alt="">
              <img class="left" src="../../assets/numberCloud/numberCloud-right.png" alt="">
            </div>
          </div>
          <!-- 战略合作单位 -->
          <div class="five" v-if="item5 != null">
            <div class="five-img">
              <img class="img5" v-if="item5 != null" :src="item5.img" alt="">
              <img class="left" src="../../assets/numberCloud/numberCloud-left.png" alt="">
            </div>
            <div class="five-nr">
              <div class="five-name" v-if="item5 != null">{{item5.title}}</div>
              <div class="five-title" v-if="item5 != null" v-html="item5.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="产品样例" class="dialog" :width="'1000px'" :visible.sync="dialogFormVisible" :close-on-click-modal=false>
      <videos :width="960" :srcUrl="item1.videoUrl" :height="452" v-if="dialogFormVisible"></videos>
    </el-dialog>
  </div>
</template>

<script>
import navigationBar from '../../components/layout/app_navigationBar'
import topItem from '../schools/components/item-box'
import videos from '../../components/tools/video-box'
export default {
	components:{navigationBar,topItem,videos},
	data() {
		return {
			cur:0,
			dialogFormVisible:false,
			item:[{title:'瀚云课堂'},{title:'瀚云教育'},{title:'瀚云智慧校园'},{title:'教育课吧/公益课堂'}],
			item1:{},
			item2:{},
			item3:{},
			item4:{},
			item5:{},
		}
	},
	created(){
		this.$store.commit('changClick',0)
		var skin = sessionStorage.getItem('Skin')
		var zskin = sessionStorage.getItem('ZSkin')
		if (skin) {
			this.$store.commit('changSkin',skin)
		}
		if (zskin) {
			this.$store.commit('changZSkin',zskin)
		}
		this.getIntroduce(1)
	},
	computed:{
		isSkin(){
			return this.$store.state.isSkin
		},
	},
	methods:{
		/**获取内容**/
		getIntroduce(index){
			var that = this
			that.$api.getIntroduce({
				params:{
					category:index,
					pageNum:1,
					pageSize:1
				}
			}).then(res=>{
				if (res.data.code == 0) {
					that.item1 = res.data.data.list[0]
					that.item2 = res.data.data.list[1]
					that.item3 = res.data.data.list[2]
					that.item4 = res.data.data.list[3]
					that.item5 = res.data.data.list[4]
				}else{
					that.item1 = {}
					that.item2 = {}
					that.item3 = {}
					that.item4 = {}
					that.item5 = {}
				}
			})
		},
		/**菜单点击按钮**/
		cilckMethod(index){
		  this.cur = index.cur;
		  this.getIntroduce(index.cur+1)
		},
		/**产品样例按钮**/
		playMethod(){
		  this.dialogFormVisible = true
		},
	}
}
</script>

<style scoped lang="scss">
.main-content{
  width: 100%;
  .headImg{
    width: 100%;
    height: 300px;
    margin-top: -80px;
    position: relative;
    .avigation{
      position: absolute;
      top: 0px;
    }
  }
  .content-view{
    display: flex;
    flex-direction: column;
    align-items: center;
    .content{
      width: 1200px;
      margin-top: 100px;
      margin-bottom: 150px;
      .top-title{
        display: flex;
        justify-items: center;
        align-items: center;
      }
      .title-view{
        width: 100%;
        background: #FCFCFC;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        padding: 60px 50px 60px 50px;
        .one{
          display: flex;
          justify-items: center;
          align-items: center;
          .one-img{
            width: 480px;
            height: 258px;
            position: relative;
			.img1{
				width: 100%;
				height: 100%;
			}
            .left{
              position: absolute;
              right: -1px;
              top: 100px;
            }
          }
          .one-nr{
            padding-left: 40px;
            .one-name{
              font-size: 24px;
              line-height: 34px;
              color: #333333;
            }
            .one-title{
              margin-top: 23px;
              font-size: 14px;
              line-height: 20px;
              text-align: justify;
              color: #333333;
            }
            .play{
              cursor: pointer;
              margin-top: 55px;
              width: 190px;
              height: 52px;
              border-radius: 4px;
              line-height: 52px;
              display: flex;
              justify-items: center;
              align-items: center;
              .name{
                padding: 0px 10px 0px 50px;
                font-size: 16px;
                color: #FFFFFF;
              }
    
            }
          }
        }
        .two{
          margin-top: 80px;
          display: flex;
          justify-items: center;
          align-items: center;
          .two-nr{
            padding-right: 40px;
            .two-name{
              font-size: 24px;
              line-height: 34px;
              color: #333333;
            }
            .two-title{
              margin-top: 23px;
              font-size: 14px;
              line-height: 20px;
              text-align: justify;
              color: #333333;
            }
          }
          .two-img{
            width: 480px;
            height: 258px;
            position: relative;
			.img2{
				width: 100%;
				height: 100%;
			}
            .left{
              position: absolute;
              left: -1px;
              top: 100px;
            }
          }
        }
        .three{
          margin-top: 80px;
          display: flex;
          justify-items: center;
          align-items: center;
          .three-img{
            width: 480px;
            height: 258px;
            position: relative;
			.img3{
				width: 100%;
				height: 100%;
			}
            .left{
              position: absolute;
              right: -1px;
              top: 100px;
            }
          }
          .three-nr{
            padding-left: 40px;
            .three-name{
              font-size: 24px;
              line-height: 34px;
              color: #333333;
            }
            .three-title{
              margin-top: 23px;
              font-size: 14px;
              line-height: 20px;
              text-align: justify;
              color: #333333;
            }
          }
        }
        .four{
          margin-top: 80px;
          display: flex;
          justify-items: center;
          align-items: center;
          .four-nr{
            padding-right: 40px;
            .four-name{
              font-size: 24px;
              line-height: 34px;
              color: #333333;
            }
            .four-title{
              margin-top: 23px;
              font-size: 14px;
              line-height: 20px;
              text-align: justify;
              color: #333333;
            }
          }
          .four-img{
            width: 480px;
            height: 258px;
            position: relative;
			.img4{
				width: 100%;
				height: 100%;
			}
            .left{
              position: absolute;
              left: -1px;
              top: 100px;
            }
          }
        }
        .five{
          margin-top: 80px;
          display: flex;
          justify-items: center;
          align-items: center;
          .five-img{
            width: 480px;
            height: 258px;
            position: relative;
			.img5{
				width: 100%;
				height: 100%;
			}
            .left{
              position: absolute;
              right: -1px;
              top: 100px;
            }
          }
          .five-nr{
            padding-left: 40px;
            .five-name{
              font-size: 24px;
              line-height: 34px;
              color: #333333;
            }
            .five-title{
              margin-top: 23px;
              font-size: 14px;
              line-height: 20px;
              text-align: justify;
              color: #333333;
            }
          }
        }
      }
    }
  }
}

</style>